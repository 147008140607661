// cart API
// handles ajax calls

function Cart(){
    var _this = this;

    this.count = 0;
    this.subtotal = 0;
    this.shipping = 0;
    this.discount = 0;
    this.total = 0;
    this.gst = 0;
    this.coupon = 0;
    this.coupon_code = '';
    this.status = 0;

    this.price = function(price) {
        return Number(price).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    this.updateQty = function (data, callback, increment) {
    	$.ajax({
            type: increment ? 'POST' : 'PUT',
            url: '/rest/cart',
            data: data,
            success: function(data) {
                if (data.status && data.status == 'combo-disabled') {
                    alert('Sorry, this combination of variants is not available.');
                    _this.status = 0;
                } else if (data.success == 1) {
                    _this.count    = data.cart.count;
                    _this.subtotal 	= data.cart.subtotal;
                    _this.shipping 	= data.cart.shipping;
                    _this.gst    	= data.cart.gst;
                    _this.discount 	= data.cart.discount;
                    _this.total    	= data.cart.total;
                    _this.discount 	= data.cart.discount;
                    _this.coupon    	= data.cart.coupon;
                    _this.coupon_code 	= data.cart.coupon_code;
                    _this.status = 1;
                } else {
                    alert(data.message);
                }
                if (typeof(callback) != 'undefined') callback();
            }
        });
    };

    this.updateShipping = function (regionId, callback) {
    	$.ajax({
    		type: 'GET',
    		url: '/rest/shipping/' + regionId,
    		success: function (data) {
    			if (data.status === 1) {
    				_this.count    = data.cart.count;
                    _this.subtotal 	= data.cart.subtotal;
                    _this.shipping 	= data.cart.shipping;
                    _this.gst    	= data.cart.gst;
                    _this.total    	= data.cart.total;
                    _this.coupon    	= data.cart.coupon;
                    _this.coupon_code 	= data.cart.coupon_code;
    			} else {
    				alert(data.message);
    			}
    			if (typeof(callback) != 'undefined') callback();
    		}
    	});
    };

    this.removeItem = function (data , callback) {
    	$.ajax({
    		type: 'GET',
    		url: '/rest/cart/remove/' + data.key,
    		success: function(data) {
    			if (data.success == 1) {
    				_this.count		= data.cart.count;
                    _this.subtotal 	= data.cart.subtotal;
                    _this.shipping 	= data.cart.shipping;
                    _this.gst    	= data.cart.gst;
                    _this.total    	= data.cart.total;
                    _this.coupon    	= data.cart.coupon;
                    _this.coupon_code 	= data.cart.coupon_code;
    			} else {
    				alert('Unable to remove item on the cart');
    			}
    			if (typeof(callback) != 'undefined') callback();
    		}
    	});
	};

	this.addCoupon = function (coupon , callback) {
    	$.ajax({
    		type: 'GET',
    		url: '/rest/cart/coupon/' + coupon,
    		success: function(data) {
    			if (data.success==1) {
    				_this.count		= data.cart.count;
                    _this.subtotal 	= data.cart.subtotal;
                    _this.shipping 	= data.cart.shipping;
                    _this.gst    	= data.cart.gst;
                    _this.total    	= data.cart.total;
                    _this.coupon    	= data.cart.coupon;
                    _this.coupon_code 	= data.cart.coupon_code;
                    _this.status    	= 1;
    			} else {
    				_this.status    	= 0;
    				alert(data.message);
    			}
    			if (typeof(callback) != 'undefined') callback();
    		}
    	});
	};

	this.removeCoupon = function (callback) {
    	$.ajax({
    		type: 'GET',
    		url: '/rest/remove/coupon',
    		success: function(data) {
    			if (data.success==1) {
    				_this.count		= data.cart.count;
                    _this.subtotal 	= data.cart.subtotal;
                    _this.shipping 	= data.cart.shipping;
                    _this.gst    	= data.cart.gst;
                    _this.total    	= data.cart.total;
                    _this.coupon    	= data.cart.coupon;
                    _this.coupon_code 	= data.cart.coupon_code;
                    _this.status    	= 1;
    			} else {
    				_this.status    	= 0;
    				alert(data.message);
    			}
    			if (typeof(callback) != 'undefined') callback();
    		}
    	});
	};
}
