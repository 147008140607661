var cart = new Cart();
var $variantSelects = $('select.variants');
var $deleteCartItem = $(".cart-item-remove");
var $addToCart = $('.add-to-cart');
var $copyBilling = $('#same-w-customer');
var $qtyItem = $('input.qty-item');
var $required = $('.required');
var $cartForm = $('#form-cart form');
var $applyCoupon = $('#applyCoupon');
var $removeCoupon = $('.remove-coupon');
var $regionArea = $('select#indiregion, select#selectArea');
var $cartAction = $('button.cart-action-btn');
var $creditCardBtn = $('button.credit-card-btn');
var $continueShoppingBtn = $('button.continue-shopping-btn');
var $cartBillingBtn = $('button.cart-billing-btn');
var $cartShippingBtn = $('button.cart-shipping-btn');
var $shoppingCartBtn = $('button.shopping-cart-btn');

$(function() {
    /**
	 * Add to Cart Event
	 * this triggers the add to cart and stores it into session
	 * @param title - (string) the title of the product
	 * @param productId - (integer) the product id
	 * @param variantStr - (string) the variant ids
	 * @param price - (decimal) the price of the product
	 * @param qty - (integer) the quantity of products purchased
	 * @param token - (string) the CSRF token for security
	 */
    $addToCart.on('click', function(e) {
		e.preventDefault();
        addToCart($(this));
		return false;
	});

    $deleteCartItem.on('click', function(e) {
        e.preventDefault();
		deleteCartItem($(this).data('key'));
	});

    $copyBilling.on('click', function() {
        copyBillingToShipping($(this));
	});

    checkVariantPricing();
    $variantSelects.on('change', function() {
        checkVariantPricing();
	});

	$qtyItem.on('keyup', function(key) {
		var qty = $(this).val();
		if ($.isNumeric(qty)) {
			$(this).trigger("change");
		}
	});

	$qtyItem.on('change', function() {
        updateQuantity($(this));
	});

    $required.append('<span> *</span>');

    $cartForm.submit(function(e) {
		if ($('input.required').val() == '') {
			var $required = $('label.required');
			$required.css('color','red');
			$required.css('font-weight','bold');
			return false;
		}
	});

    $applyCoupon.on('click', function(e){
		e.preventDefault();
        applyCoupon($(this));
	});

	$removeCoupon.on('click', function(e){
		e.preventDefault();
		removeCoupon();
	});

	$regionArea.on('change', function() {
		changeRegion($(this));
	});
    $regionArea.trigger('change');

    $cartAction.click(function(e) {
		cartAction($(this));
		return false;
	});

    $creditCardBtn.on('click', function(e){
		clickCreditCardBtn();
		return false;
	});

    $continueShoppingBtn.click(function(e) {
        window.location.href = '/products';
    });

    $cartBillingBtn.click(function(e) {
        window.location.href = '/billing';
    });

    $cartShippingBtn.click(function(e) {
        window.location.href = '/billing';
    });

    $shoppingCartBtn.click(function(e) {
        window.location.href = '/cart';
    });
});

function parseCartAmount(elem, amount)
{
    elem.text(amount);
}

function checkVariantPricing()
{
    if ($variantSelects.length > 0) {
        var id = $addToCart.data('id'),
            variants = [];

        $variantSelects.each(function( index ) {
            variants[index] = $(this).val();
        });

        $.ajax({
            type: 'GET',
            url: '/products/pricing/' + id,
            data: {'variants': variants},
            success: function(data) {
                if (data.status && data.status == 'combo-disabled') {
                    alert('Sorry, this combination of variants is not available.');
                } else {
                    console.log(data);
                    $addToCart.data('price',data.price);
                    $addToCart.data('sku', data.sku);

                    $('.crprice').text(Number(data.price)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));

                    var $origPriceContainer = $('.original-price-container');
                    if (data.price != data.originalPrice) {
                        $('.original-price').text(Number(data.originalPrice)
                            .toFixed(2)
                            .toString()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                        $origPriceContainer.show();
                    } else {
                        $origPriceContainer.hide();
                    }
                }
            }
        });
    }
}

function copyBillingToShipping($selector)
{
    if ($selector.is(":checked")) {
        $('input#title').val($selector.data('title'));
        $('input#firstname').val($selector.data('firstname'));
        $('input#lastname').val($selector.data('lastname'));
        $('input#company').val($selector.data('company'));
        $('input#address1').val($selector.data('address1'));
        $('input#address2').val($selector.data('address2'));
        $('input#city').val($selector.data('city'));
        $('input#postcode').val($selector.data('postcode'));
        $('input#country').val($selector.data('country'));
        $('input#phone').val($selector.data('phone'));
        $('input#email').val($selector.data('email'));
        $('input#comments').val($selector.data('comment'));

        var $select = $('select#selectArea'),
            region = $selector.data('region');

        $select.find('option').each(function(position, element) {
            if ($(element).val() == region)
                $select.get(0).sumo.selectItem(position);
        });

        $select.trigger('change');
    }
}

function deleteCartItem(key)
{
    var data = {'key': key};
    cart.removeItem(data, function() {
        parseCartAmount($('.cart-subtotal'), cart.price(cart.subtotal));
        parseCartAmount($('.cart-gst'), cart.price(cart.gst));
        parseCartAmount($('.cart-total'), cart.price(cart.total));
        parseCartAmount($('.cart-shipping'), cart.price(cart.shipping));

        $('span.header-cart-count').text('(' + cart.count + ')');

        $('.line-' + key).remove();
        if (parseInt(cart.count) == 0) {
            $('.checkout-flow-footer').remove();
            $('.product-table-with-action-column').remove();
            $('.page-shopping-cart .content-max-width').append('<p>No products in cart.</p>');
        }
    });
}

function addToCart($selector)
{
    var variantIds = [];
    var qty = $('#qty-' + $selector.data('id')).val();

    if ($variantSelects.length) {
        $variantSelects.each(function( index ) {
            variantIds[index] = $(this).val();
        });
    }

    if (qty == "") {
        alert("Invalid product quantity. Please enter numeric value");
    } else {
        if (qty > 0) {
            qty = parseInt(qty);
            var data = {
                '_token': $('input[name="_token"]').val(),
                'id': $selector.data('id'),
                'variants': variantIds,
                'qty': qty
            };

            cart.updateQty(data, function(response) {
                if (cart.status == 1) {
                    $('span.header-cart-count').text('(' + cart.count + ')');
                    alert("Product has been added to cart");
                }
            }, true);
        }
    }
}

function updateQuantity($selector)
{
    var qty = $selector.val();
    var itemId = $selector.data('id');

    if (!$.isNumeric(qty)) {
        $selector.val(1);
    } else {
        if (parseInt(qty) > 0) {
            var v = String($selector.data('variants'));
            var variants = null;

            if (v.indexOf(',') > -1){
                variants = v.split(',')
            } else if (v != 'undefined' && v != null && v != '') {
                variants = [v];
            }

            var data = {
                '_token': $('input[name="_token"]').val(),
                'id': itemId,
                'variants': variants,
                'qty': parseInt(qty)
            };

            cart.updateQty(data, function(){
                var calc = parseInt(qty) * $selector.data('price');

                $(".cart-total").text(cart.price(cart.total));
                $(".cart-subtotal").text(cart.price(cart.subtotal));
                $(".cart-gst").text(cart.price(cart.gst));
                $('.item-total-' + itemId).text(Number(calc)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));

                $('.total-w-gst').text('$'+ cart.price(cart.total));
                $('.cart-shipping').text(cart.price(cart.shipping));
                $('span.header-cart-count').text('(' + cart.count + ')');
            });
        } else {
            $selector.val(1);
        }
    }
}

function applyCoupon($selector)
{
    var coupon = $('#coupon_value').val();
    if (coupon =='') {
        alert('Invalid coupon');
    } else {
        cart.addCoupon(coupon, function(){
            if (cart.status == 1) {
                $(".cart-subtotal").text(cart.price(cart.subtotal));
                $(".cart-discount").text(cart.price(cart.coupon));
                $(".cart-gst").text(cart.price(cart.gst));
                $(".cart-total").text(cart.price(cart.total));
                $('span.header-cart-count').text('(' + cart.count + ')');
                $('.cart-shipping').text(cart.price(cart.shipping));
                $('.total-w-gst').text('$'+ Number(cart.total)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                $('.coupon-name').text(cart.coupon_code);
                $('.cart-coupon').text(cart.price(cart.coupon));
                $('.coupon-container').show();

                $selector.text('Coupon Applied');
                alert('Coupon has been added');
            }
        });
    }
}

function removeCoupon()
{
    cart.removeCoupon(function(){
        $(".cart-subtotal").text(cart.price(cart.subtotal));
        $(".cart-discount").text(cart.price(cart.coupon));
        $(".cart-gst").text(cart.price(cart.gst));
        $(".cart-total").text(cart.price(cart.total));
        $('span.header-cart-count').text('(' + cart.count + ')');
        $('.cart-shipping').text(cart.price(cart.shipping));
        $('.total-w-gst').text('$'+ Number(cart.total)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));

        $('.coupon-container').hide();
        $('#applyCoupon').text('Apply');
        alert('Coupon has been removed');
    });
}

function changeRegion($selector)
{
    if ($selector.val() > 0) {
        cart.updateShipping($selector.val(), function() {
            $('.cart-subtotal').text(cart.price(cart.subtotal));
            $('.cart-gst').text(cart.price(cart.gst));
            $('.cart-total').text(cart.price(cart.total));
            $('.cart-shipping').text(cart.price(cart.shipping));
        });
    }

    if ($selector.val() == '')
        $(".SumoSelect > .CaptionCont > span").css('color', '#999');
    else
        $(".SumoSelect > .CaptionCont > span").css('color', '#000');
}

function cartAction($selector)
{
    if ($selector.hasClass('review-btn')) {
        if ($('.tandc').is(':checked') == 1)
            window.location.href = $selector.data('link');
        else
            alert('You need to accept the terms and conditions before placing the order');
    } else {
        window.location.href = $selector.data('link');
    }
}

function clickCreditCardBtn()
{
    if ($('.tandc').is(':checked') == 1) {
        $('.review-frm').submit();
    } else {
        alert('You need to accept the terms and conditions before placing the order');
    }
}