$(document).ready(function() {

  /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
      $(this).validate({
        submitHandler: function(form) {
          form.submit();
        },
        rules: {
          required: true
        },
        errorPlacement: function(error, element) {
          error.appendTo(element.parents('.form-field'));
        },
        highlight: function(element, errorClass, validClass) {
          if (element.type === 'radio') {
            this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
          } else {
            $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
          }
        },
        unhighlight: function(element, errorClass, validClass) {
          if (element.type === 'radio') {
            this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
          } else {
            $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
          }
        }
      });
    });


    /*******************************************/
    //Custom Select Dropdown 
    $(function() {
      $('.custom-select-dropdown').selectric();
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
      type:'inline',
      showCloseBtn: true,
      closeOnBgClick: true,
      removalDelay: 300,
      mainClass: 'mfp-zoom-in',
      midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
      e.preventDefault();
      $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
      e.preventDefault();
      $('.js-tab-triggers a').not(this).removeClass('active');
      $(this).addClass('active');
      var getTarget = $(this).attr('href');
      $('.tab-content').not(getTarget).hide();
      $(getTarget).show();
    });

    /*******************************************/

  });


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
  if (this.html() == t1) this.html(t2);
  else this.html(t1);
  return this;
};


/*******************************************/
//Main Menu
function slideMenu(){ 
  $('#mobile-menu').toggle();
}

$('#mobile-menu li').each(function(){
  $(this).has("ul").addClass('mobile-menu-parent');
});

$( ".mobile-menu-parent .icon" ).on("click", function(){
  $(this).siblings('ul').slideToggle(150);
});

//prevent slide up
$(".mobile-menu-parent").on("click", "ul", function(event){
  event.stopPropagation()
})

/*******************************************/
// Header Highlighter
$(document).ready(function() {
  var headerHref = "#site-header";
  var mobileHref = "#mobile-menu";
  var myURL = "/" + location.pathname.split('/')[1];

  $(headerHref + ' li').each(function(){
    if ($(this).find('a').attr('href') == myURL) {
      $(this).find('a').addClass("selected")
      return
    }
  })

  $(mobileHref + ' li').each(function(){
    if ($(this).find('a').attr('href') == myURL) {
      $(this).find('a').addClass("selected")
      return
    }
  })
});

/*******************************************/



/**************************************/
//Print page function

$('.js-print-page').click(function(){
  window.print();
});
// wishlist API
// handles ajax calls

function Wishlist(){
  var _this = this;

  this.items = {};
  this.count = 0;
  this.status = 0;
  this.errors = '';

  this.updateQty = function(data, callback, increment) {
    $.ajax({
      type: increment ? 'POST' : 'PUT',
      url: '/rest/wishlist',
      data: data,
      success: function(data) {
        if (data.status && data.status == 'combo-disabled') {
          alert('Sorry, this combination of variants is not available.');
          _this.status = 0;
        } else if (data.status == 'success') {
          _this.setWishlistData(data);
        } else {
          alert(data.message);
        }
        if (typeof(callback) != 'undefined') callback();
      },
      error: function(response) {
        console.log('error', response.responseText);
      }
    });
  };

  this.removeItem = function(data, callback) {
    $.ajax({
      type: 'GET',
      url: '/rest/wishlist/remove/' + data.key,
      success: function(data) {
        if (data.status == 'success') {
          _this.setWishlistData(data);
        } else {
          console.log('error', 'Unable to remove item on the wishlist');
        }
        if (typeof(callback) != 'undefined') callback();
      },
      error: function(response) {
        console.log('error', response.responseText);
      }
    });
  };

  this.setWishlistData = function(data) {
    _this.items     = data.wishlist.items;
    _this.count     = data.wishlist.count;
    _this.status    = 1;
  };

  this.getErrorsFromJson = function(json) {
    var errors = '';        
    $.each(json, function(key, value){
      var string = value[0];
      var replacements = ['"', '{', '}', '[', ']'];
      var index = 0;
      for (index = 0; index < replacements.length; index++) {
        string = string.replace(replacements[index], '');
      }
      errors += string + "\n";
    });
    
    return errors;
  };
}

var wishlist = new Wishlist();
/*
 * Requires data-id attribute of product ID
 * You may give the quantity field the id qty-{ID}
 */
 var $addToWishlistBtn = $('.do-add-to-wishlist');
/*
 * Requires data-key attribute (session key)
 */
 var $deleteFromWishlistBtn = $('.do-delete-from-wishlist');
/*
 * This is the select dropdowns with variant options
 */
 var $variantSelects = $('select.variants');
/*
 * Requires data-id attribute of product ID
 * Add this class to quantity input items within the wishlist
 */
 var $quantityField = $('.do-change-quantity-from-wishlist');

/*
 * Apply this class to any items which holds the wishlist count
 */
 var $wishlistCounters = $('.wishlist-counter');

/*
 * Add this class to checkout continue buttons so they are hidden during AJAX requests
 */
 var $wishlistHideOnAjax = $('.js-hide-on-ajax');

 $(document).ready(function(){
  $addToWishlistBtn.click(function(e){
    e.preventDefault();
    addToWishlist($(this));
    return false;
  });

  $quantityField.change(function(){
    updateQuantity($(this));
  });

  $deleteFromWishlistBtn.click(function(){
    deleteWishlistItem($(this));
  });
});


/****************************************************************************************************************************
 WISHLIST FUNCTIONS
 ****************************************************************************************************************************/

/**
 *
 */
 function addToWishlist($selector)
 {
  var qty = parseInt($('#qty-' + $selector.data('id')).val());
  if (!qty || qty == 0) {
    qty = 1;
  }

  if (qty > 0) {

    var data = {
      'id': $selector.data('id'),
      'variants': [],
      'qty': qty
    };

    $wishlistHideOnAjax.hide();
    wishlist.updateQty(data, function() {
      wishlistUpdateHtml(wishlist);
      console.log('success', 'Item has been added to your wishlist');
      window.location.href = "/wishlist";
    }, true);
  }
}

function updateQuantity($selector)
{
  var itemId = $selector.data('id');
  var qty = parseInt($selector.val());
  if (qty == 0) qty = 1;

  var data = {
    '_token': $('input[name="_token"]').val(),
    'id': itemId,
    'variants': getVariants($selector.data('variants')),
    'qty': qty
  };

  $wishlistHideOnAjax.hide();
  wishlist.updateQty(data, function(){
    wishlistUpdateHtml(wishlist);
  });
}

function deleteWishlistItem($selector)
{
  if ($selector.data('key') != '') {
    var key = $selector.data('key');
    $wishlistHideOnAjax.hide();
    wishlist.removeItem({key: key}, function() {
      $('#' + key).remove();
      wishlistUpdateHtml(wishlist);
    });
  }
}

/****************************************************************************************************************************
 PRIVATE FUNCTIONS
 ****************************************************************************************************************************/
 function wishlistUpdateHtml(wishlist)
 {
  wishlistUpdateItems(wishlist);
  $wishlistCounters.html(wishlist.count);
}

function wishlistUpdateItems(wishlist)
{
  if (wishlist.items) {
    $.each(wishlist.items, function(key, value) {
      var $row = $('#' + key);
      if ($row.length > 0) {
                // var $price = $row.find('.js-price');
                // var $unitPrice = $row.find('.js-unit-price');

                // var itemPrice = parseFloat(value.price);
                // var priceHtml = (itemPrice * value.qty).toFixed(2);

                // if ($price.html().indexOf('$') !== -1)
                //     priceHtml = '$' + priceHtml;

                // $price.html(priceHtml);
                // $unitPrice.html(itemPrice.toFixed(2));
              }
            });
  }
}
// Remove blue highlight on click
document.addEventListener("touchstart", function(){}, true);




